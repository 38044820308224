import * as React from "react"
import { graphql } from "gatsby"
import Header from "../components/header"
import Footer from "../components/footer"
import PageRenderer from "../components/page-renderer"
import Seo from "../components/seo"

const PagePost = ({ data }: any) => {  
  const page = data.strapiPage  

  return (
    <>
      {/* SEO */}
      <Seo seo={{metaTitle: page.title, metaDescription: page.description}} />

      {/* Navigation */}
      <Header/>

      <PageRenderer blocks={page.blocks}/>

      {/* Footer */}
      <Footer/>
    </>
  )
}

export const pageQuery = graphql`
  query ($slug: String) {
    strapiPage(slug: { eq: $slug }) {
      id
      slug
      title
      description
      blocks {
        ...ServicePageBlocks
      }
    }
  }
`

export default PagePost